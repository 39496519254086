<template>
  <c-box
    id="ImageContainer"
    h="72px"
    w="72px"
    rounded="md"
    pos="relative"
  >
    <c-box
      v-if="!loaded"
      class="loader"
      pos="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
    />
    <c-image
      h="72px"
      w="72px"
      rounded="md"
      object-fit="contain"
      v-bind="$attrs"
      v-on="$listeners"
      @load="onLoaded"
    />
  </c-box>
</template>

<script >
import { ref } from "@vue/composition-api";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  let loaded = ref(false);

  function onLoaded($event) {
    loaded.value = true;
  }

  return {
    loaded,
    onLoaded
  };
};

export default __sfc_main;
</script>


<style scoped>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 100%;
  border-top: 5px solid #008c81;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  /* position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px); */
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
