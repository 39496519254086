<template>
  <c-flex
    flex="1"
    flex-direction="column"
    justify-content="center"
    align-items="center"
    my="60px"
  >
    <c-image
      :h="['189px', '300px']"
      :src="require('@/assets/icon-empty-coaching-course.svg')"
      alt="empty coaching course illustration"
    />
    <c-text
      mt="41px"
      color="gray.900"
      :fontSize="['14px', '24px']"
      :lineHeight="['21px', '36px']"
      textAlign="center"
      v-html="label"
    >
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: "ProfileEmptyPage",
  props: ["label"],
};
</script>
