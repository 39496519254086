<template>
  <c-box id="glossaries-page">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box
        w="100%"
        maxWidth="1000px"
        mx="auto"
        v-chakra="{
          '> nav': {
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
        }"
      >
        <Breadcrumb
          :items="[
            { href: '/', label: 'Beranda' },
            { label: 'Glossary foto makan', isCurrent: true },
          ]"
        />

        <c-box
          w="100%"
          :maxWidth="['inherit', '1200px']"
          mx="auto"
          :marginTop="['20px', '50px']"
          :display="['block', 'block']"
          :borderBottom="['1px solid #F2F2F2', 'none']"
          :paddingBottom="['15px', '0']"
          px="20px"
        >
          <c-flex justifyContent="space-between">
            <c-heading
              :display="['none', 'block']"
              fontSize="24px"
              lineHeight="32px"
              color="black.900"
            >
              Glossary foto makan
            </c-heading>
          </c-flex>
          <c-flex
            :w="['100%', 'auto']"
            :marginTop="['0', '30px']"
            :marginBottom="['0', '30px']"
            :flexDirection="['column', 'row']"
          >
            <c-form-control pos="relative" w="100%">
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                pos="absolute"
                top="10px"
                left="15px"
                zIndex="2"
              />
              <c-input
                type="text"
                w="100%"
                id="searchId"
                placeholder="Cari jenis makanan"
                background="superLightGray.900"
                borderRadius="12px"
                paddingLeft="50px"
                pos="relative"
                zIndex="1"
                v-model.lazy="search"
                v-chakra="{
                  '&::placeholder': {
                    color: 'gray.900',
                  },
                }"
              />
            </c-form-control>
            <c-button
              v-if="!hasFilter"
              type="button"
              :ml="['0', '10px']"
              :marginTop="['20px', '0']"
              :marginBottom="['20px', '0']"
              px="15px"
              py="4px"
              color="gray.900"
              fontSize="16px"
              lineHeight="24px"
              rounded="full"
              bg="#F2F2F2"
              @click.stop.prevent="openFilter = true"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                marginRaight="5px"
              />
              <c-text
                font-weight="bold"
                font-size="16px"
                line-height="150%"
                color="#888888"
              >
                Filter
              </c-text>
            </c-button>
            <c-button
              v-else
              type="button"
              :ml="['0', '10px']"
              :marginTop="['20px', '0']"
              :marginBottom="['20px', '0']"
              px="15px"
              py="4px"
              color="gray.900"
              fontSize="16px"
              lineHeight="24px"
              rounded="full"
              bg="#008C8133"
              border="1px solid #008C81"
              @click.stop.prevent="openFilter = true"
            >
              <c-image
                :src="require('@/assets/ic-filter-active.svg')"
                alt="icon"
                display="inline-block"
                marginRaight="5px"
              />
              <c-text
                font-weight="bold"
                font-size="16px"
                line-height="150%"
                color="#008C81"
              >
                Filter
              </c-text>
            </c-button>
          </c-flex>

          <c-button
            v-if="group && group != ''"
            type="button"
            ml="10px"
            px="15px"
            py="4px"
            color="gray.900"
            fontSize="16px"
            lineHeight="24px"
            rounded="full"
            bg="#008C8133"
            border="1px solid #008C81"
            @click.prevent.stop="group = null"
          >
            <c-text
              font-weight="bold"
              font-size="16px"
              line-height="150%"
              color="#008C81"
            >
              {{ group }}
            </c-text>
            <c-icon ml="10px" name="close" color="brand.900" size="14px" />
          </c-button>

          <c-flex
            justify-content="flex-start"
            grid-gap="10px"
            overflow-x="auto"
            overflow-y="hidden"
            h="65px"
            py="5px"
            mt="20px"
            pl="5px"
          >
            <c-button
              v-for="item in chars"
              :key="item"
              type="button"
              ml="5px"
              h="32px"
              w="32px"
              min-w="32px"
              px="0"
              m="0"
              fontSize="16px"
              lineHeight="24px"
              rounded="full"
              text-transform="uppercase"
              :color="char === item ? 'brand.900' : 'gray.900'"
              :bg="char === item ? '#008C8133' : 'white'"
              border-width="1px"
              border-style="solid"
              :border-color="char === item ? '#008C81' : 'gray.900'"
              @click.stop.prevent="char = item"
            >
              {{ item }}
            </c-button>
          </c-flex>

          <!-- Glossaries List -->
          <c-flex mt="20px" grid-gap="20px" flex-wrap="wrap" :justifyContent="['center', 'start']">
            <template v-if="glossaries.length > 0">
              <c-flex
                v-for="(item, idx) in glossaries"
                :key="idx"
                flex-dir="column"
                align-items="center"
                flex-shrink="0"
                w="120px"
              >
                <SpinningImg :src="item.photoUrl" />
                <c-text
                  font-size="12px"
                  line-height="150%"
                  text-align="center"
                  color="#333333"
                  max-w="110px"
                  overflow="hidden"
                  v-chakra="{
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                  }"
                >
                  {{ item.ingredientName | trim }}
                </c-text>
                <c-text
                  font-size="12px"
                  line-height="150%"
                  text-align="center"
                  color="#888888"
                >
                  {{ item.dose }} {{ item.doseUnit }}
                </c-text>
              </c-flex>
            </template>
            <EmptyCard v-else label="Glossary foto makan tidak tersedia" />
          </c-flex>
        </c-box>
      </c-box>
    </c-box>

    <Portal to="main" v-if="openFilter">
      <c-flex
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        zIndex="11"
        bg="#000000aa"
        justifyContent="center"
        alignItems="center"
      >
        <c-box bg="white" rounded="lg" :min-w="['100%', '600px']" overflow="hidden" :max-h="['80%', '700px']">
          <c-flex>
            <c-button
              type="button"
              variant="ghost"
              variant-color="brand"
              @click.stop.prevent="openFilter = false"
            >
              <c-icon name="close" size="16px" />
            </c-button>
          </c-flex>
          <c-list h="600px" p="15px" :pb="['200px', '15px']" overflow-y="scroll">
            <c-list-item
              v-for="(name, idx) in listGroup"
              :key="idx"
              h="70px"
              d="flex"
              :border-top="idx != 0 ? '1px solid #c4c4c4' : ''"
              align-items="center"
              justify-content="space-between"
            >
              <c-button
                type="button"
                w="100%"
                d="flex"
                align-items="center"
                justify-content="space-between"
                variant="ghost"
                @click.prevent.stop="onFilter(name)"
              >
                <c-text
                  font-style="normal"
                  font-weight="normal"
                  font-size="14px"
                  line-height="150%"
                  color="#333333"
                >
                  {{ name }}
                </c-text>
                <c-icon
                  v-if="name === group"
                  name="check"
                  size="16px"
                  color="brand.900"
                />
              </c-button>
            </c-list-item>
          </c-list>
        </c-box>
      </c-flex>
    </Portal>
    <Footer />
  </c-box>
</template>

<script>
import { Portal } from "portal-vue"
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import Breadcrumb from "@/components/breadcrumb.vue"
import EmptyCard from "@/views/profile/empty.vue"
import SpinningImg from "@/components/spinning-img.vue"

export default {
  name: "GlossariesPage",
  components: { Portal, Header, Footer, Breadcrumb, EmptyCard, SpinningImg },
  data() {
    return {
      openFilter: false,
      glossaries: [],
      listGroup: [],
      chars: Array.from({ length: 122 - 97 + 1 }, (_, idx) =>
        String.fromCharCode(97 + idx)
      ),
    }
  },
  computed: {
    search: {
      get() {
        return this.$route.query.q ?? ""
      },
      set(search) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            q: search,
          },
        })
      },
    },
    char: {
      get() {
        return this.$route.query.char
      },
      set(char) {
        if (char === this.$route.query.char) return
        this.$router.push({ query: { char } })
      },
    },
    group: {
      get() {
        return this.$route.query.group
      },
      set(group) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            group,
          },
        })
      },
    },
    filters() {
      return {
        q: this.search,
        char: this.char,
        foodGroup: this.group,
      }
    },
    hasFilter() {
      return this.group != null && this.group !== ""
    },
  },
  watch: {
    filters: {
      immediate: true,
      async handler({ q, foodGroup, char }) {
        console.log({ q, foodGroup, char })
        let data = await this.$store.dispatch("glossary/list", {
          q,
          foodGroup,
          char,
        })

        this.glossaries = data.result
        this.listGroup = data.filter
      },
    },
  },
  methods: {
    onFilter(name) {
      console.log("set filter group", name)
      this.group = name
      this.openFilter = false
    },
  },
  filters: {
    trim(value, length = 18) {
      if (value.length > length) {
        return `${value.substring(0, length)}...`
      }
      return value
    },
  },
}
</script>

<style scoped>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 100%;
  border-top: 5px solid #008c81;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  /* position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px); */
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
